:root {
    --primary-color: #FDFBF6;
    --primary-color-alpha: gray;
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Helvetica,Arial,sans-serif;
    background-color: var(--primary-color); /* add this */
  }
  
  html, body, #root {
    height: 100%;
  }
  
  #root {
    height: 100%;
  }
  
  header {
    background-color: var(--primary-color);
    color: black;
    display: flex;
    justify-content: left;
    align-items: center;
    height: fit-content;
    font-size: 1rem;
    font-weight: 50;
    font-family: Helvetica,Arial,sans-serif;
    letter-spacing: 2px;
    padding-left: 50px;
  }
  .blinking-cursor {
    display: inline-block;
    font-weight: lighter;
    height: 12px;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .login-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    border: 1px solid black;
    top: 10px;
    right: 110px;
  }
  .about-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    align-items: center;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 5px;
    border: 1px solid black;
  }
  .login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .login-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  
  .login-popup-content {
    background-color: var(--primary-color);
    color: black;
    border: 2px solid black;
    padding: 20px;
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .login-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .login-popup-close:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }

  .no-click > * {
    opacity: 0.5;
  }
  .no-click > .login-popup {
    opacity: 1;
  }
  .no-click > .register-popup {
    opacity: 1;
  }
  .register-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    align-items: center;
    padding: 10px;
    font-size: 1.5rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    border: 1px solid black;
  }
  
  .register-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .register-popup-content {
    background-color: var(--primary-color);
    color: black;
    border: 2px solid black;
    padding: 20px;
    width: 400px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .register-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .register-popup-close:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  .thumb {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: space-evenly;
  }
  .thumb-h2{
    font-size: 2em;
  }
  .thumb-p {
    font-weight: lighter;
    font-size: 2em;
  }
  .landing-body {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  .writing-image {
    width: 100%;
    height: 100%;
  }
  .thumb-pic {
    width: 120%;
    height: 90vh;
  }
  .sign-in-button {
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    border: 1px solid black;
    padding-top: 5px;
  }

  .googleImage {
    width: 20px;
    height: 20px;
    display: inline-block;
    transform: translate(0px, 3px);
  }

