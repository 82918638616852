
.reading-title-box {
    display: inline-block;
    background-color: #FDFBF6;
    border: 2px solid gray;
    padding: 5px;
    margin-top: 10px;
    border-right: none;
    border-bottom: none;
    border-left: none;
    width: 80%;
    overflow-x: auto;
    white-space: nowrap;
  }
  


.reading-title-box h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: lighter;
}