.summary-check-content {
    display: flex;
    width: 90%;
    flex-direction: column;
    background-color: #f0f0f0;
    height: 80vh;
    border: 0.5px solid #c2c2c2;
    border-top: none;
}
.summary-check-content h2 {
    text-align: center;
    font-weight: lighter;
}
.summary-check-textarea {
    font-family: Arial, sans-serif;
    font-size: 1rem;
    font-weight: lighter;
    padding: 0.5rem;
    resize: both;
    border:1px solid black;
    border-right: none;
    border-left: none;
    min-width: 100%;
    max-width: 100%;
    min-height: fit-content;
    max-height: 35vh; /* subtract the margin-bottom from the parent height */
    box-sizing: border-box; 
    background-color: #f0f0f0;
}
.submit-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 100%;
}
.submit-summary-button {
    font-weight: lighter;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    border: 1px solid black;
}
.response-box {
    border:1px solid black;
    padding: 0.5rem;
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    transition: all 0.5s ease;
}
.response-box.hide {
    display: none;
  }
.response-box-loading {
    justify-content: center;
    align-items: center;
    align-self: center;
    border:1px solid black;
    padding: 0.5rem;
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    transition: all 0.5s ease;
}
