.join-reading {
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    margin: 20px;
    width: 75%;
    font-size: 1.2rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
}

.join-reading input[type="text"],
.join-reading textarea {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    font-size: 1rem;
    border: 1px solid gray;
    border-radius: 3px;
}

.join-reading input[type="submit"] {
    background-color: black;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.join-reading input[type="submit"]:hover {
    background-color: gray;
}

.code-input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.code-input button {
    margin-left: 10px;
}

.button-container {
    display: flex;
    justify-content: center;
}
.join-reading-button {
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: relative;
    border: 1px solid black;
}
.clear-button {
    border: none;
    font-size: normal;
    font-weight: lighter;
    cursor: pointer;
    position: relative;
    border: 1px solid black;
    margin-bottom: 10px;
}