.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
  }
.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    align-items: center;
    min-width: auto;
    min-height: auto;
  }
  
  .dropzone p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #aaa;
  }
  
  .dropzone:hover {
    cursor: pointer;
    border-color: black;
  }
  .doc-contents {
    margin: 20px auto;
    font-family: Helvetica,Arial,sans-serif;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #FAF2DF;
    max-width: 800px;
    overflow-wrap: break-word;
    max-height: 400px;
    overflow-y: auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .url-input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .url-input-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .show-sample-button {
    border: none;
    font-size: 1rem;
    font-weight: lighter;
    cursor: pointer;
    border: 1px solid black;
    margin-bottom: 5px;
  }
  .custom-title-box {
    width: 90%;
    box-sizing: border-box;
  }
  .input-options {
    display: inline-block;
    border: 1px solid black;
    padding-top: 1px;
  }
