.reading-analytics-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .reading-analytics-popup-content {
    background-color: var(--primary-color);
    color: black;
    border: 2px solid black;
    padding: 20px;
    width: 500px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .reading-analytics-navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .reading-analytics-summary-progress{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .reading-analytics-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .reading-analytics-popup-close:hover {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .reading-analytics-button {
    background-color: var(--primary-color);
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: relative;
    border: 1px solid black;
    border-top: none;
    border-right: none;
    border-left: none;
  }
  
  .reading-analytics-button.selected {
    border-bottom: 2px solid black;
  }
  .reading-analytics-participants-list {
    display: inline-block;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    height: 100%;
  }
  .reading-analytics-participants-list-item h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: lighter;
  }