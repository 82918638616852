.create-reading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 5%;
    align-self: center;
    width: 90%;
    max-height: wrap-content;
    font-weight: lighter;
    font-size: 1.2rem;
    font-family: Helvetica, Arial, sans-serif;
}


    
.create-reading input[type="text"],
.create-reading textarea {
width: 100%;
padding: 5px;
margin-bottom: 10px;
font-size: 1rem;
border: 1px solid gray;
}

.create-reading input[type="submit"] {
background-color: black;
color: white;
padding: 10px;
border: none;
border-radius: 3px;
cursor: pointer;
}

.create-reading input[type="submit"]:hover {
background-color: gray;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.create-reading-button {
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: relative;
    border: 1px solid black;
}