.reading-listing-card {
  display: flex;
  flex-direction: row;
}
.reading-title-box {
    display: inline-block;
    background-color: #FDFBF6;
    border: 2px solid gray;
    padding: 5px;
    margin-top: 10px;
    border-right: none;
    border-bottom: none;
    border-left: none;
    width: 80%;
    overflow-x: auto;
    white-space: nowrap;
  }
  
.reading-title-box h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: lighter;
}
.delete-button {
  border: none;
  font-weight: lighter;
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
}
.reading-management-field {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px; /* This part helps set up how for is the icon from the border because this container would wrap up the icon */
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.classroom-icon,
.settings-icon {
  width: 25px;
  height: 25px;
}
.icon-container:hover {
  background-color: rgba(128, 128, 128, 0.2);
}