:root {
  --primary-color: #FDFBF6;
  --primary-color-alpha: gray;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Helvetica,Arial,sans-serif;
  background-color: var(--primary-color); /* add this */
}

html, body, #root {
  height: 100%;
}
body textarea:focus{
  outline: none;
}
body input:focus{
  outline: none;
}
body select:focus{
  outline: none;
}

#root {
  height: 100%;
}

header {
    background-color: var(--primary-color);
    color: black;
    display: flex;
    justify-content: left;
    align-items: center;
    height: fit-content;
    font-size: 1rem;
    font-weight: 50;
    font-family: Helvetica,Arial,sans-serif;
    letter-spacing: 2px;
    padding-left: 50px;
  }
  .blinking-cursor {
    display: inline-block;
    font-weight: lighter;
    height: 12px;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  button {
    transition: all 0.2s ease-in-out;
  }
  button:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }

  .todo-button {
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    border: 1px solid black;
    top: 10px;
    right: 110px;
  }
  .user-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    align-items: center;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 53px;
    border: 1px solid black;
  }
  .menu-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    align-items: center;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid black;
  }

  .pencil-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  .user-icon {
    width: 20px;
    height: 16px;
  }
  .menu-icon {
    width: 20px;
    height: 16px;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .logout-button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    padding: 5;
  }
  
  .sidebar {
    position: absolute;
    top: 50px;
    left: auto;
    right: 0;
    width: 150px;
    height: wrap-content;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    border-radius: 0.25rem;
    z-index: 1;
  }
  .sidebar-button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    padding: 5px;
    display: block;
    width: 100%;
    text-align: left;
  }
  
  .active-sidebar-button {
    background-color: black;
    color: white;
  }
  .created-readings {
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    overflow-y: auto;
    height: 50%;
    width: 100%;
  }
  .created-readings h2 {
    margin-bottom: 0;
    font-weight: normal;
  }
  .created-readings-box {
    border: 1px solid black;
    border-right: none;
    border-bottom: none;
    border-left: none;
    flex: auto;
    justify-content: first baseline;
    overflow-y: auto;
    margin: 35px;
    max-height: 200px;
    width: 80%;
  }
  .create-reading-modal {
    position: absolute;
    top:50%;
    left: 50%;
    width: 60%;
    height: 50%;
    transform: translate(-50%, -80%);
    z-index: 2;
  }


  .joined-readings {
    display: flex;
    flex-direction: column;
    align-items: first baseline;
    overflow-y: auto;
    height: 50%;
    width: 100%;
  }
  .joined-readings h2 {
    margin-bottom: 0;
    font-weight: normal;
  }
  .joined-readings-box {
    border: 1px solid black;
    border-right: none;
    border-bottom: none;
    border-left: none;
    flex: auto;
    justify-content: first baseline;
    overflow-y: auto;
    margin: 35px;
    max-height: 200px;
    width: 80%;
  }
  .joined-reading-modal {
    position: absolute;
    top:50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -80%);
    z-index: 2;
  }

.joined-readings-link { 
  color: black;
  transition: all 0.2s ease-in-out;
}
.joined-readings-link:hover { 
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
.home-body {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.toDoList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 40;
  font-family: Helvetica,Arial,sans-serif;
  letter-spacing: 2px;
  padding-left: 50px;
}

.directions-content {
  position: relative;
  flex-direction: column;
  height: wrap-content;
  max-height: fit-content;
  width: 30%;
  max-width: fit-content;
}
.directions-content h2 {
  text-align: left;
  padding-right: 10%;
  font-weight: normal;
}
.directions-content-box {
  font-size: large;
  list-style-type: none;
  border: 1px solid black;
  border-right: none;
  border-bottom: none;
  border-left: none;
  flex: auto;
  font-weight: lighter;
  justify-content: first baseline;
  width: 90%;
}
.directions-content-box li::before {
  content: "\2192"; /* Use the right arrow symbol code */
  display: inline-block; 
  margin-right: 0.5em;
}
.delete-reading-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.delete-reading-popup-content {
  background-color: var(--primary-color);
  color: black;
  border: 2px solid black;
  padding: 20px;
  width: 400px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.delete-reading-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.delete-reading-popup-close:hover {
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
.delete-reading-button {
  border: none;
  padding: 10px;
  font-size: 1rem;
  font-weight: normal;
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
  position: relative;
  border: 1px solid black;
}
