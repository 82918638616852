  
  .blinking-cursor {
    display: inline-block;
    font-weight: lighter;
    height: 12px;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .todo-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 110px;
    border: 1px solid black;
    transition: all 0.2s ease-in-out;
  }
  .todo-button:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  .user-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    align-items: center;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 53px;
    border: 1px solid black;
    transition: all 0.2s ease-in-out;
  }
  .user-button:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  .menu-button {
    background-color: var(--primary-color);
    color: black;
    border: none;
    align-items: center;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border: 1px solid black;
    transition: all 0.2s ease-in-out;
  }
  .menu-button:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  .pencil-icon {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
  .user-icon {
    width: 20px;
    height: 16px;
  }
  .menu-icon {
    padding-top: 3px;
    width: 20px;
    height: 13px;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100px;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .logout-button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    padding: 5;
  }
  
  .logout-button:hover {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  .sidebar {
    position: absolute;
    top: 50px;
    left: auto;
    right: 0;
    width: 150px;
    height: wrap-content;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
    border-radius: 0.25rem;
    z-index: 1;
  }
  .sidebar-button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    padding: 5px;
    display: block;
    width: 100%;
    text-align: left;
  }
  .show-reading-assignment {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: lighter;
  }
  .instruction-content {
    flex-direction: column;
    height: wrap-content;
    max-height: fit-content;
    width: 30%;
  }
  .instruction-content h2 {
    text-align: left;
    padding-right: 10%;
    font-weight: normal;
  }
  .instruction-content-box {
    font-size: large;
    list-style-type: none;
    border: 1px solid black;
    border-right: none;
    border-bottom: none;
    border-left: none;
    flex: auto;
    justify-content: first baseline;
    width: 90%;
  }
  .instruction-content-box li::before {
    content: "\2192"; /* Use the right arrow symbol code */
    display: inline-block; 
    margin-right: 0.5em;
  }
  .reading-content {
    flex-direction: column;
    padding-left: 35px;
    padding-right: 50px;
    height: 100%;
    width: 100%;
  }
  .reading-title {
    text-align: left;
    padding-right: 50px;
    margin-bottom: 10px;
  }
  .reading-body {
    text-align: justify;
    font-size: 100%;
    font-weight: 400;
    max-height: 80vh;
    overflow-y: scroll;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .summary-check {
    flex-direction: column;
    border: 1px solid black;
    border-right: none;
    border-bottom: none;
    border-left: none;
    height: wrap-content;
    max-height: fit-content;
    width: 30%;
  }