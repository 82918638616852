
.preview {
    display: flex;
    flex-direction: row;
}
.preview-reading-content {
    flex-direction: column;
    height: 100%;
    width: 70%;
  }
.preview-reading-title {
    text-align: left;
    padding-right: 50px;
    margin-bottom: 10px;
}
.preview-reading-title h2{
    font-weight: normal;
}
.preview-reading-body {
    text-align: justify;
    font-size: 100%;
    font-weight: 400;
    max-height: 80vh;
    overflow-y: scroll;
    border: solid 1px black;
    border-bottom: none;
    border-right: none;
    border-left: none;
}
.preview-summaries {
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    min-width: 30%;
    max-width: 30%;
    width: 30%;
    justify-content: center;
    padding-left: 10%;
    padding-top: 10%;
}
.summary-preview textarea{
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    max-width: 100%;
    height: 260px;
    max-height: 260px;;    
    /* the height of the parent its 360px, which is the verticalViewPortHeight in the Carousel component */
    /* You should not make the height goes beyond 280, since it would cause overflow and mess up the transition */
    border: solid 1px black;
    border-bottom: none;
    border-right: none;
    border-left: none;
    resize: none;
    overflow-y: auto;
}
.preview-summaries-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 20px;
}
.preview-summaries-button {
    border: none;
    padding: 10px;
    font-size: 1rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
    position: relative;
    border: 1px solid black;
}